import Vue from 'vue'

Vue.mixin({
  methods: {
    stToastError (message) {
      this.$bvToast.toast(message, {
        variant: 'danger',
        headerClass: 'd-none',
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    },
    stToastSuccess (message) {
      this.$bvToast.toast(message, {
        variant: 'success',
        headerClass: 'd-none',
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    }
  }
})
