export const TOKEN_VIEW = 'view'
export const TOKEN_HANDLE_IDENTIFICATION = 'handle-identification'
export const SIGN_STORAGE_KEY = 'sign'

export const parseTokenFromUrl = () => location.pathname.split('/').filter(t => !!t).pop()

export const parseAndStoreUrlToSession = () => {
  const data = {
    token: parseTokenFromUrl()
  }

  const hashParts = location.hash.replace('#', '').split('/')
  data.tokenSecret = hashParts[0]
  data.verificationToken = hashParts[1]
  data.verificationTokenSecret = hashParts[2]

  sessionStorage.setItem(SIGN_STORAGE_KEY, JSON.stringify(data))
}

export const parseDataFromSession = () => {
  const data = sessionStorage.getItem(SIGN_STORAGE_KEY)
  return data ? JSON.parse(data) : null
}
