export const state = () => ({
  party: null,
  loadingParty: true,
  signing: false,
  language: null
})

export const mutations = {
  SET_PARTY (state, party) {
    state.party = party
    state.loadingParty = false
  },
  SET_SIGNING (state, signing) {
    state.signing = signing
  },
  SET_LANGUAGE (state, language) {
    state.language = language
  }
}

export const actions = {
  async loadParty ({ commit, dispatch }) {
    try {
      const response = await this.$api.getDocument()
      await commit('SET_PARTY', response.data.data)

      // Update UI language based on party's language
      if (response.data.data.language) {
        await dispatch('setLanguage', response.data.data.language)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      await commit('SET_PARTY', null)
    }
  },
  async sign ({ commit }, data) {
    try {
      await commit('SET_SIGNING', true)
      const response = await this.$api.sign(data)
      await commit('SET_PARTY', response.data.data)
      await commit('SET_SIGNING', false)
    } catch (e) {
      await commit('SET_SIGNING', false)
      throw e
    }
  },
  async setLanguage ({ commit }, language) {
    await commit('SET_LANGUAGE', language)
  }
}
