import { parseDataFromSession } from '~/utils/sign'

export default function (context, inject) {
  const client = context.$axios.create({
    baseURL: context.$config.apiUrl
  })

  client.onRequest((config) => {
    config.headers.common['x-token-secret'] = getData().tokenSecret
  })

  inject('api', {
    getDocument () {
      const { token } = getData()
      return client.get(`/api/sign/${token}`)
    },
    sign (data) {
      const { token } = getData()
      return client.post(`/api/sign/${token}/sign`, data)
    },
    identification: {
      create (data) {
        return client.post('/api/identifications', data)
      },
      getMethods () {
        return client.get('/api/identifications/methods')
      }
    },
    verification: {
      verifyToken (tokenId, tokenSecret) {
        return client.post('/api/verifications/verify-token', {
          token_id: tokenId,
          token_secret: tokenSecret
        })
      }
    }
  })
}

/**
 * Returns token and tokenSecret from
 * the current browser url
 */
let data = null
function getData () {
  if (!data) {
    data = parseDataFromSession()
  }
  return data
}
