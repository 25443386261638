import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { LANGUAGE_FI } from '~/utils/constants'

Vue.use(VueI18n)

const DEFAULT_LANGUAGE = LANGUAGE_FI

export default ({ app }, inject) => {
  // Set i18n instance on app. This way we can use
  // it in middleware and pages asyncData/fetch
  const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE,
    fallbackLocale: DEFAULT_LANGUAGE,
    dateTimeFormats: {
      fi: {
        time: {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        },
        short: {
          year: 'numeric', month: 'numeric', day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      }
    },
    messages: {
      fi: {
        common: require('~/locales/fi/common.json'),
        p: require('~/locales/fi/pages.json'),
        c: require('~/locales/fi/components.json')
      }
    }
  })

  app.i18n = i18n

  // Listen for store changes and update i18n language
  // and persist the language to the localStorage
  app.store.watch(
    state => state.language,
    (language) => {
      setLanguage(language)
      i18n.locale = language
    }
  )

  // Set initial locale
  app.store.dispatch('setLanguage', DEFAULT_LANGUAGE)
}

const STORAGE_KEY = 'language'

export const setLanguage = (language) => {
  localStorage.setItem(STORAGE_KEY, language)
}

export const getLanguage = defaulValue => localStorage.getItem(STORAGE_KEY) || defaulValue
